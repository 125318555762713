import { ChangeDetectorRef, Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ISUserRoleEnum } from '@ess/integrated-search/shared/utils';

import { IntegratedSearchClientsDataAccessClientsFacade } from '../state';

@UntilDestroy()
@Directive({
  selector: '[isRoles]',
  standalone: true,
})
export class IntegratedSearchClientsDataAccessRoleDirective {
  @Input()
  set isRoles(allowedRoles: ISUserRoleEnum[] | undefined) {
    this.__updateVisibility(allowedRoles);
  }

  constructor(
    private readonly __templateRef: TemplateRef<any>,
    private readonly __viewContainer: ViewContainerRef,
    private readonly __clientsFacade: IntegratedSearchClientsDataAccessClientsFacade,
    private readonly __cdr: ChangeDetectorRef,
  ) {}

  private __updateVisibility(allowedRoles: ISUserRoleEnum[] | undefined): void {
    this.__clientsFacade
      .isClientRoleAllowed$(allowedRoles)
      .pipe(untilDestroyed(this))
      .subscribe((visible) => {
        this.__viewContainer.clear();
        visible && this.__viewContainer.createEmbeddedView(this.__templateRef);
        this.__cdr.markForCheck();
      });
  }
}
