import { Injectable } from '@angular/core';
import { Actions } from '@ngneat/effects-ng';
import { first, map, Observable } from 'rxjs';

import {
  ISCategoryDetails,
  ISClient,
  ISGoogleAdsAccount,
  ISGoogleAdsAccountDetails,
  ISUserRoleEnum,
} from '@ess/integrated-search/shared/utils';
import { SharedSortDirection } from '@ess/shared/utils/models';

import { IntegratedSearchClientsDataAccessClientsActions as ClientsActions } from './integrated-search-clients-data-access-clients.actions';
import { IntegratedSearchClientsDataAccessClientsRepository } from './integrated-search-clients-data-access-clients.repository';

import { IntegratedSearchClientsDataAccessClientsService } from '../services';

@Injectable()
export class IntegratedSearchClientsDataAccessClientsFacade {
  readonly clients$: Observable<ISClient[]> = this.__repo.clients$;
  readonly activeClient$: Observable<ISClient | undefined> = this.__repo.activeClient$;
  readonly loading$: Observable<boolean> = this.__repo.loading$;
  readonly role$: Observable<ISUserRoleEnum | undefined> = this.__repo.role$;
  readonly sort$: Observable<SharedSortDirection> = this.__repo.sort$;
  readonly fetched$: Observable<boolean> = this.__repo.fetched$;
  readonly error$: Observable<string | null> = this.__repo.error$;

  get activeClientSlug(): string {
    return this.__repo.activeClientSlug;
  }

  constructor(
    private readonly __repo: IntegratedSearchClientsDataAccessClientsRepository,
    private readonly __actions: Actions,
    private readonly __clientsService: IntegratedSearchClientsDataAccessClientsService,
  ) {}

  init(): Observable<boolean> {
    this.__actions.dispatch(ClientsActions.init());
    return this.__repo.initialized$.pipe(first());
  }

  toggleSort(): void {
    this.__actions.dispatch(ClientsActions.toggleSort());
  }

  setActiveClient(slug: string | null): string | undefined {
    return this.__repo.setActiveClient(slug);
  }

  readonly isClientRoleAllowed$: (allowedRoles: ISUserRoleEnum[] | undefined) => Observable<boolean> = (
    allowedRoles: ISUserRoleEnum[] | undefined,
  ) => {
    return this.role$.pipe(
      map(
        (role) => role === ISUserRoleEnum.PLATFORM_ADMIN || !allowedRoles || (!!role && allowedRoles?.includes(role)),
      ),
    );
  };

  getGoogleAdsAccounts(): Observable<ISGoogleAdsAccount[]> {
    return this.__clientsService.getGoogleAdsAccounts(this.activeClientSlug);
  }

  getGoogleAdsAccountDetails(account_id: string): Observable<ISGoogleAdsAccountDetails> {
    return this.__clientsService.getGoogleAdsAccountDetails(this.activeClientSlug, account_id);
  }

  getGoogleSCAccounts(): Observable<string[]> {
    return this.__clientsService.getGoogleSCAccounts(this.activeClientSlug);
  }

  fetchAllActiveCategories(): Observable<ISCategoryDetails[]> {
    return this.__clientsService.fetchAllActiveCategories(this.activeClientSlug);
  }
}
