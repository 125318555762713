import { Injectable } from '@angular/core';
import { createEffect, ofType, tapResult } from '@ngneat/effects';
import { map, switchMap, tap } from 'rxjs';

import { IntegratedSearchAuthDataAccessAuthActions as AuthActions } from '@ess/integrated-search/auth/data-access';
import { ISClientsList } from '@ess/integrated-search/shared/utils';

import { IntegratedSearchClientsDataAccessClientsService } from './../services';

import {
  IntegratedSearchClientsDataAccessClientsActions as ClientsActions,
  IntegratedSearchClientsDataAccessClientsRepository,
} from './';

@Injectable()
export class IntegratedSearchClientsDataAccessClientsEffects {
  init$ = createEffect((actions) =>
    actions.pipe(
      ofType(ClientsActions.init),
      map(() => this.__repo.setStoreInitialized()),
    ),
  );

  loadClients$ = createEffect((actions) =>
    actions.pipe(
      ofType(ClientsActions.loadClients, AuthActions.authSuccessFinished),
      tap(() => this.__repo.setClientsLoading()),
      switchMap(() =>
        this.__clientsService.getClients().pipe(
          tapResult(
            (clientsList: ISClientsList) => this.__repo.saveClients(clientsList.items),
            () => this.__repo.handleLoadClientsFail(),
          ),
        ),
      ),
    ),
  );

  toggleSort$ = createEffect((actions) =>
    actions.pipe(
      ofType(ClientsActions.toggleSort),
      map(() => this.__repo.toggleSort()),
    ),
  );

  constructor(
    private readonly __clientsService: IntegratedSearchClientsDataAccessClientsService,
    private readonly __repo: IntegratedSearchClientsDataAccessClientsRepository,
  ) {}
}
