import { Injectable } from '@angular/core';
import { Actions } from '@ngneat/effects-ng';
import { createStore, select, setProps, withProps } from '@ngneat/elf';
import {
  resetActiveId,
  selectActiveEntity,
  selectAllEntities,
  setActiveId,
  setEntities,
  withActiveId,
  withEntities,
} from '@ngneat/elf-entities';
import { combineLatestWith, map, Observable, shareReplay } from 'rxjs';

import { ISClient, ISStoreEnum, ISUserRoleEnum } from '@ess/integrated-search/shared/utils';
import { SharedActiveEntitiesStore, SharedSortDirection } from '@ess/shared/utils/models';

import { IntegratedSearchClientsDataAccessClientsActions as ClientsActions } from './integrated-search-clients-data-access-clients.actions';

interface StoreProps {
  initialized: boolean;
  fetched: boolean;
  loading: boolean;
  error: string | null;
  sort: SharedSortDirection;
}

type Store = SharedActiveEntitiesStore<ISClient, string, StoreProps>;

@Injectable()
export class IntegratedSearchClientsDataAccessClientsRepository {
  get activeClient$(): Observable<ISClient | undefined> {
    return this.__store.pipe(selectActiveEntity());
  }

  get clients$(): Observable<ISClient[]> {
    return this.__store.pipe(
      selectAllEntities(),
      shareReplay({ refCount: true }),
      combineLatestWith(this.sort$),
      map(([clients, sort]) => clients.sort((a, b) => a.name.localeCompare(b.name) * (sort === 'asc' ? 1 : -1))),
    );
  }

  get sort$(): Observable<SharedSortDirection> {
    return this.__store.pipe(select((state) => state.sort));
  }

  get loading$(): Observable<boolean> {
    return this.__store.pipe(select((state) => state.loading));
  }

  get initialized$(): Observable<boolean> {
    return this.__store.pipe(select((state) => state.initialized));
  }

  get fetched$(): Observable<boolean> {
    return this.__store.pipe(select((state) => state.fetched));
  }

  get role$(): Observable<ISUserRoleEnum | undefined> {
    return this.activeClient$.pipe(map((client: ISClient | undefined) => client?.role));
  }

  get error$(): Observable<string | null> {
    return this.__store.pipe(select((state) => state.error));
  }

  get activeClientSlug(): string {
    return this.__store.getValue().activeId;
  }

  private readonly __store: Store;

  constructor(private readonly __actions: Actions) {
    this.__store = this.__createStore();
  }

  setActiveClient(slug: string | null): string | undefined {
    let activeClient: string | undefined;
    if (slug && this.__store.getValue().entities[slug]) {
      activeClient = slug;
    }
    this.__store.update(activeClient ? setActiveId(activeClient) : resetActiveId());
    this.__actions.dispatch(ClientsActions.setActiveClientSuccess({ slug: activeClient ?? null }));
    return activeClient;
  }

  setStoreInitialized(): void {
    this.__store.update(setProps({ initialized: true }));
  }

  setClientsLoading(): void {
    this.__store.update(setProps({ loading: true }));
  }

  saveClients(clients: ISClient[]): void {
    this.__store.update(setEntities(clients));
    this.__store.update(setProps({ loading: false, fetched: true }));
  }

  handleLoadClientsFail(): void {
    this.__store.update(setEntities([]));
    this.__store.update(
      setProps({
        loading: false,
        fetched: true,
        error: 'Something went wrong during fetching the list. Try again later.',
      }),
    );
  }

  toggleSort(): void {
    this.__store.update((state) => ({ ...state, sort: state.sort === 'asc' ? 'desc' : 'asc' }));
  }

  private __createStore(): Store {
    return createStore(
      { name: ISStoreEnum.CLIENTS },
      withProps<StoreProps>({
        initialized: false,
        fetched: false,
        loading: false,
        error: null,
        sort: 'asc',
      }),
      withEntities<ISClient, 'slug'>({ idKey: 'slug' }),
      withActiveId(),
    );
  }
}
