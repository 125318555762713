import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { provideEffects } from '@ngneat/effects-ng';

import { IntegratedSearchClientsDataAccessClientsService } from './services';
import {
  IntegratedSearchClientsDataAccessClientsEffects,
  IntegratedSearchClientsDataAccessClientsFacade,
  IntegratedSearchClientsDataAccessClientsRepository,
} from './state';

@NgModule()
export class IntegratedSearchClientsDataAccessModule {
  static forRoot(): ModuleWithProviders<IntegratedSearchClientsDataAccessModule> {
    return {
      ngModule: IntegratedSearchClientsDataAccessModule,
      providers: [
        provideEffects(IntegratedSearchClientsDataAccessClientsEffects),
        IntegratedSearchClientsDataAccessClientsService,
        IntegratedSearchClientsDataAccessClientsFacade,
        IntegratedSearchClientsDataAccessClientsRepository,
        {
          provide: APP_INITIALIZER,
          useFactory: (facade: IntegratedSearchClientsDataAccessClientsFacade) => () => facade.init(),
          deps: [IntegratedSearchClientsDataAccessClientsFacade],
          multi: true,
        },
      ],
    };
  }
}
