import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import {
  ISCategoryDetails,
  ISClientsList,
  ISGoogleAdsAccount,
  ISGoogleAdsAccountDetails,
  ISGoogleAdsAccountsList,
  ISGoogleSCAccountsList,
} from '@ess/integrated-search/shared/utils';
import { SharedDataAccessApiService } from '@ess/shared/data-access';
import { SharedListResponse } from '@ess/shared/utils/models';

@Injectable()
export class IntegratedSearchClientsDataAccessClientsService {
  private readonly __resource = 'clients';

  constructor(private readonly __apiService: SharedDataAccessApiService) {}

  getClients(): Observable<ISClientsList> {
    return this.__apiService.get<ISClientsList>(this.__resource);
  }

  getGoogleAdsAccounts(slug: string): Observable<ISGoogleAdsAccount[]> {
    return this.__apiService
      .get<ISGoogleAdsAccountsList>(`${this.__resource}/${slug}/gads-accounts`)
      .pipe(map((response) => response.items));
  }

  getGoogleAdsAccountDetails(slug: string, account_id: string): Observable<ISGoogleAdsAccountDetails> {
    return this.__apiService.get<ISGoogleAdsAccountDetails>(`${this.__resource}/${slug}/gads-accounts/${account_id}`);
  }

  getGoogleSCAccounts(slug: string): Observable<string[]> {
    return this.__apiService
      .get<ISGoogleSCAccountsList>(`${this.__resource}/${slug}/gsc-accounts`)
      .pipe(map((response) => response.items));
  }

  fetchAllActiveCategories(slug: string): Observable<ISCategoryDetails[]> {
    // TODO switch to proper endpoint once ready
    return this.__apiService
      .get<SharedListResponse<ISCategoryDetails>>(`${this.__resource}/${slug}/categories?page=1&page_size=9999`)
      .pipe(
        map((response: SharedListResponse<ISCategoryDetails>) => response.items.filter((category) => category.active)),
      );
  }
}
