/* eslint-disable @typescript-eslint/member-ordering */
import { actionsFactory, props } from '@ngneat/effects';

import { ISStoreEnum } from '@ess/integrated-search/shared/utils';

export class IntegratedSearchClientsDataAccessClientsActions {
  private static readonly __factory = actionsFactory(ISStoreEnum.CLIENTS);

  static readonly init = this.__factory.create('Init clients store');
  static readonly loadClients = this.__factory.create('Fetch clients list');
  static readonly toggleSort = this.__factory.create('Toggle sort');
  static readonly setActiveClientSuccess = this.__factory.create(
    'Active client change success',
    props<{ slug: string | null }>(),
  );
}
